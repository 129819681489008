.pac-container{
  // border: 1px solid red;
  border-radius: 0 0 5px 5px;
}
.pac-item{
  color: #1B3256;
  // opacity: 0.6;
  // font-weight: 200;
}
.pac-icon{
  display: none;
}
.pac-matched{
  color: #1B3256;
  // opacity:0.2;
}
.pac-item-query{
  color: #1B3256;
}
